import { Spinner } from "app/common/components/Spinner"
import { redirectTo } from "app/core/constants"
import { CmsRouteMgr } from "app/core/routes"
import getEntityCounts from "app/dashboard/queries/getEntityCounts"
import getTopCities from "app/dashboard/queries/getTopCities"
import getTopProvinces from "app/dashboard/queries/getTopProvinces"
import getTopSearches from "app/dashboard/queries/getTopSearches"
import getTopStockists from "app/dashboard/queries/getTopStockists"
import NortherCms from "app/layouts/CmsLayout"
import { BlitzPage, Link, useQuery } from "blitz"
import { isFunction } from "formik"
import { array } from "fp-ts"
import { pipe } from "fp-ts/function"
import React, { ReactNode, Suspense } from "react"
import { tw } from "twind"

interface Column<T> {
  accessor: keyof T | ((t: T) => ReactNode)
  title: string
}

interface TableProps<T> {
  caption?: string
  className?: string
  columns: Column<T>[]
  results: T[]
}

const Table = <T,>({ caption, className, columns, results }: TableProps<T>) => (
  <table className={tw(className, `text-sm`)}>
    {caption && <caption className={tw(`text(left lg)`, `mb-2`, `font-bold`)}>{caption}</caption>}
    <thead>
      <tr className={tw(`text-left`)}>
        {columns.map((col, idx) => (
          <th className={tw(`px-3 py-1`, `bg-gray-200`)} key={idx}>
            {col.title}
          </th>
        ))}
      </tr>
    </thead>
    <tbody>
      {results.map((r, idx) => (
        <tr className={tw(`bg-gray(100 even:50)`)} key={idx}>
          {pipe(
            columns,
            array.mapWithIndex((idx, col) => (
              <td className={tw(`px-3 py-1`)} key={idx}>
                {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                {isFunction(col.accessor) ? col.accessor(r) : (r as any)[col.accessor]}
              </td>
            ))
          )}
        </tr>
      ))}
    </tbody>
  </table>
)

const Dashboard = () => {
  const [topCities] = useQuery(getTopCities, null)

  const [provinceCounts] = useQuery(getTopProvinces, null)

  const [entityCounts] = useQuery(getEntityCounts, null)

  const [topSearchTerms] = useQuery(getTopSearches, null)

  const [topStockists] = useQuery(getTopStockists, null)

  return (
    <div className={tw(`grid gap-8 items-start`, `ram-lg`)}>
      <Table
        caption="Entities"
        columns={[
          { accessor: "brandCount", title: "Brands" },
          { accessor: "stockistCount", title: "Stockists" },
          { accessor: "categoryCount", title: "Categories" },
          { accessor: "tagCount", title: "Tags" },
          { accessor: "cityCount", title: "Cities" },
        ]}
        results={[entityCounts]}
      />
      <Table
        caption="Top Search Terms"
        columns={[
          { accessor: "term", title: "Term" },
          { accessor: "count", title: "Count" },
        ]}
        results={topSearchTerms}
      />
      <Table
        caption="Top Cities"
        columns={[
          { accessor: "cityName", title: "City" },
          { accessor: "provinceName", title: "Province" },
          { accessor: "brandCount", title: "Brands" },
          { accessor: "stockistCount", title: "Stockists" },
        ]}
        results={topCities}
      />
      <Table
        caption="Top Provinces"
        columns={[
          { accessor: "provinceName", title: "Province" },
          { accessor: "brandCount", title: "Brands" },
          { accessor: "stockistCount", title: "Stockists" },
        ]}
        results={provinceCounts}
      />
      <Table
        caption="Top Stockists"
        columns={[
          {
            accessor: ({ id: stockistId, name }) => (
              <Link href={CmsRouteMgr.CmsStockistEditPage({ stockistId })}>
                <a className={tw(`hover:text-blue-700`)}>{name}</a>
              </Link>
            ),
            title: "Stockist",
          },
          { accessor: "brandCount", title: "Brands" },
        ]}
        results={topStockists}
      />
    </div>
  )
}

// ts-prune-ignore-next
export const getStaticProps = async () => {
  return {
    props: {},
  }
}

const CmsDashboard: BlitzPage = () => {
  return (
    <Suspense fallback={<Spinner />}>
      <Dashboard />
    </Suspense>
  )
}

CmsDashboard.authenticate = { redirectTo }
CmsDashboard.getLayout = (page) => <NortherCms title="NortherCMS">{page}</NortherCms>

// ts-prune-ignore-next
export default CmsDashboard
